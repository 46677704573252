//TODO: create in same way as others: gatsby-node.js
import React from "react";
import LayoutDefault from "../componentsxx/LayoutDefault";
import { SEO } from "../componentsxx/SEO";
import ServicesBody from "../componentsxx/ServicesBody";
import { textObj } from "../text/text";

//TODO: For now just pointing to card data
const { headerText, descriptionText } = textObj.servicesSection.cardDataText[3];

//appending unique string to paragraphs to create unique content
const UNIQUE_DESC_TEXT =
  "As affordable tree service contractors, we provide professional and affordable tree removal services in PA since 2017.";

const TreeRemovalPage = () => {
  return (
    <LayoutDefault>
      <ServicesBody
        headerText={"Affordable Tree Service"}
        descriptionText={`${UNIQUE_DESC_TEXT} ${descriptionText}`}
      />
    </LayoutDefault>
  );
};

export default TreeRemovalPage;
export const Head = () => (
  <SEO title="Affordable Tree Service In PA" description={UNIQUE_DESC_TEXT} />
);
